import React, { Component } from 'react';
import s from './DummyDonutsContainer.module.css';

class DummyDonutsContainer extends Component {
  render() {
    const { titles } = this.props;

    return (
      <div className={s.root}>
        {Object.keys(titles).map(key => (
          <div className={s.container} key={key}>
            <div className={s.item}>
              <h3>{titles[key] || key}</h3>
              <div className={s.donut} />
              <div className={s.table}>
                {[0, 1, 2].map((item) => (
                  <div className={s.row} key={item}>
                    <div className={s.color} />
                    <div className={s.name} />
                    <div className={s.persentage} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default DummyDonutsContainer;
