import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import s from './DonutTable.module.css';

class DonutTable extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    allData: PropTypes.array.isRequired,
    getColor: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.expandable = props.data.length < props.allData.length;
    this.state = {
      expanded: false,
      data: props.data,
    };
  }

  getPersentage = value => {
    if (this.state.expanded) {
      const roundedValue = Math.round(value * 1e4) / 1e2;

      if (roundedValue >= 0.01) {
        return `${roundedValue}%`;
      }

      return '<0.01%';
    }

    if (value > 0.02) {
      return `${Math.round(value * 100)}%`;
    }

    return '<2%';
  };

  handleClick = () => {
    this.setState({
      expanded: !this.state.expanded,
      data: this.state.expanded ? this.props.data : this.props.allData,
    });
  };

  putExpandButton = () => {
    if (!this.expandable) {
      return null;
    }

    return (
      <button className={s.more} onClick={this.handleClick}>
        {this.state.expanded ? <FormattedMessage id="DonutTable.less" defaultMessage="less" /> : <FormattedMessage id="DonutTable.more" defaultMessage="more" />}
      </button>
    );
  };

  render() {
    return (
      <div className={s.root}>
        {this.state.data.map(item => {
          if (!item.name || !item.amount) {
            return null;
          }

          return <div className={s.row} key={item.name}>
              <div className={s.color}>
                <div  className={s.colorCircle}
                  style={{ backgroundColor: this.props.getColor(item.name) }}
                >
                  &nbsp;
                </div>
              </div>
              <div className={s.name}>{item.name}</div>
              <div className={s.persentage}>
                {this.getPersentage(item.amount)}
              </div>
            </div>
        })}
        {this.putExpandButton()}
      </div>
    );
  }
}

export default DonutTable;
