import React, { Component } from 'react';
import s from './DonutTooltip.module.css';

class DonutTooltip extends Component {
  render() {
    const { active, payload } = this.props;

    if (!active || payload.length < 1) {
      return null;
    }

    return (
      <div className={s.root}>
        <span>{`${payload[0].name}: ${Math.round(payload[0].value * 100)}%`}</span>
      </div>
    );
  }
}

export default DonutTooltip;
