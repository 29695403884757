import 'whatwg-fetch';
import 'utils/object.isInfinite.polyfill.js';

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash/get';
import Layout from 'components/layout'
import Hero from 'components/Layout/Hero';
import Bottom from 'components/Bottom/Bottom';
import GetStarted from 'components/GetStarted';
import StatisticsContainer from 'components/statistics/StatisticsContainer';

class Statistics extends Component {
  render () {
    return (
      <Layout page="statistics" lang={_get(this.props, 'pageContext.lang', 'en')}>
        <Hero
          headerIntl={<FormattedMessage id="Statistics.statistics" defaultMessage="Statistics" />}
          subHeaderIntl={<FormattedMessage id="Statistics.caption" defaultMessage="Hive OS network statistics" />}
        />
        <div>
          <StatisticsContainer />
          <GetStarted />
          <Bottom />
        </div>
      </Layout>
    );
  }
}

export default Statistics;
