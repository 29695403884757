import { defineMessages } from 'react-intl';

export const statisticsIntlTitles = defineMessages({
  coins: {
    id: 'StatisticsContainer.coins',
    defaultMessage: 'Coins'
  },
  algos: {
    id: 'StatisticsContainer.algorithmsGpu',
    defaultMessage: 'Algorithms/GPUs'
  },
  miners: {
    id: 'StatisticsContainer.minersGpu',
    defaultMessage: 'Miners/GPUs'
  },
  gpuBrands: {
    id: 'StatisticsContainer.gpuBrands',
    defaultMessage: 'GPU Brands'
  },
  amdModels: {
    id: 'StatisticsContainer.amdModels',
    defaultMessage: 'AMD Models'
  },
  nvidiaModels: {
    id: 'StatisticsContainer.nvidiaModels',
    defaultMessage: 'Nvidia Models'
  },
  asicModels: {
    id: 'StatisticsContainer.asicModels',
    defaultMessage: 'ASIC Models'
  },
})