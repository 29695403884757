import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import DonutTooltip from 'components/statistics/DonutTooltip';
import DonutTable from 'components/statistics/DonutTable';
import s from './Donut.module.css';

const RADIAN = Math.PI / 180;

class Donut extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    getColor: PropTypes.func,
  };

  static defaultProps = {
    getColor: () => '#eee',
  };

  constructor(props) {
    super(props);

    this.data = this.sliceTinyValues(props.data);
  }

  getTotal = data => data.reduce((acc, current) => acc + current.amount, 0);

  sliceTinyValues(data) {
    const sliced = data
      .sort((curr, next) => next.amount - curr.amount)
      .reduce((acc, current) => {
        if ((current.amount > 0.02) // gather 2%
          && (acc.length < 15) // less than 15 items in array
        ) {
          acc.push(current)
        }
        return acc;
      }, []);

    const totalInSliced = this.getTotal(sliced);

    if (totalInSliced < 1) {
      sliced.push({ name: 'other', amount: 1 - totalInSliced});
    }

    return sliced;
  }

  putLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const value = (percent * 100).toFixed(0);

    if (value < 2) {
      return null;
    }

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="middle"
        fontWeight="bold"
        fontSize="14px"
      >
        {`${value}%`}
      </text>
    );
  };

  render() {
    return (
      <div className={s.root}>
        <h3>{this.props.title}</h3>
        <PieChart width={300} height={300} className={s.pieChart}>
          <Pie
            dataKey="amount"
            data={this.data}
            innerRadius={55}
            outerRadius={120}
            paddingAngle={0}
            labelLine={false}
            label={this.putLabel}
            stroke="none"
            isAnimationActive={true}
          >
            {this.data.map(item => (
              <Cell key={item.name} fill={this.props.getColor(item.name)} />
            ))}
          </Pie>
          <Tooltip content={<DonutTooltip />}/>
        </PieChart>
        <DonutTable
          data={this.data}
          allData={this.props.data}
          getColor={this.props.getColor}
        />
      </div>
    );
  }
}



export default Donut;
