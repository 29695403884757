import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Donut from 'components/statistics/Donut';
import {
  getCoinUnitsColor,
  getAlgColor,
  getMinerColor,
  getBrandsGpusColor,
  getAsicModelsColor,
  getAmdModelsColor,
  getNvidiaModelsColor,
} from 'helpers/pieColors';
import s from './DonutsContainer.module.css';

const colorFuncByKey = {
  coins: getCoinUnitsColor,
  algos: getAlgColor,
  miners: getMinerColor,
  gpuBrands: getBrandsGpusColor,
  amdModels: getAsicModelsColor,
  nvidiaModels: getAmdModelsColor,
  asicModels: getNvidiaModelsColor,
};

class DonutsContainer extends Component {
  static propTypes = {
    data: PropTypes.shape({
      algos: PropTypes.array,
      miners: PropTypes.array,
      gpuBrands: PropTypes.array,
      amdModels: PropTypes.array,
      nvidiaModels: PropTypes.array,
      asicModels: PropTypes.array,
    }).isRequired,
    titles: PropTypes.shape({}),
  };

  static defaultProps = {
    titles: {},
  }

  getTitle = name => {
    return this.props.titles[name] || name;
  };

  render() {
    const { data } = this.props;

    return (
      <div className={s.root}>
        {Object.keys(data).map(key => (
          <Donut
            key={key}
            title={this.getTitle(key)}
            data={data[key]}
            getColor={colorFuncByKey[key]}
          />
        ))}
      </div>
    );
  }
}

export default DonutsContainer;
