import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import DonutsContainer from 'components/statistics/DonutsContainer';
import DummyDonutsContainer from 'components/statistics/DummyDonutsContainer';
import s from './StatisticsContainer.module.css';
import { statisticsIntlTitles } from 'constants/statisticsIntlTitles';

const STATISTICS_URL =
  process.env.STATISTICS_URL || 'https://api2.hiveos.farm/api/v2/hive/stats';

const TITLES = {
  coins: 'coins',
  algos: 'algos',
  miners: 'miners',
  gpuBrands: 'gpuBrands',
  amdModels: 'amdModels',
  nvidiaModels: 'nvidiaModels',
  asicModels: 'asicModels'
};

class StatisticsContainer extends Component {
  state = {
    loadingError: false,
    isLoading: true,
  };

  componentDidMount() {
    fetch(STATISTICS_URL)
      .then((response) => response.json())
      .then((response) => {
        this.statistics = {
          coins: response.coins,
          algos: response.algos,
          miners: response.miners,
          gpuBrands: response.gpu_brands,
          amdModels: response.amd_models,
          nvidiaModels: response.nvidia_models,
          asicModels: response.asic_models,
        }

        this.setState({
          loadingError: false,
          isLoading: false
        });
      })
      .catch(() => {
        this.setState({
          loadingError: true,
          isLoading: false
        });
      });
  }

  getMessages = messages => {
    const { intl } = this.props;

   return Object.keys(messages).reduce((prepObj, key) => ({ ...prepObj, [key]: intl.formatMessage(statisticsIntlTitles[key]) }), {})
  }

  render() {
    const { loadingError, isLoading } = this.state;
    const { intl } = this.props;

    if (isLoading) {
      const data = [{
          coins: intl.formatMessage(statisticsIntlTitles.coins),
          algos: intl.formatMessage(statisticsIntlTitles.algos),
        }, {
          amdModels: intl.formatMessage(statisticsIntlTitles.amdModels),
          nvidiaModels: intl.formatMessage(statisticsIntlTitles.nvidiaModels),
          asicModels: intl.formatMessage(statisticsIntlTitles.asicModels)
        }, {
          miners: intl.formatMessage(statisticsIntlTitles.miners),
          gpuBrands: intl.formatMessage(statisticsIntlTitles.gpuBrands)
      }];

      return (
        <div className={s.root}>
          {data.map((row, index) => {
            return <DummyDonutsContainer key={index} titles={row} />
          })}
        </div>
      );
    }

    if (loadingError
      || !this.statistics
      || !this.statistics.coins
    ) {
      return (
        <div className={s.root}>
          Ooops. Loading statistics failed
        </div>
      );
    }

    const {
      coins,
      algos,
      miners,
      gpuBrands,
      amdModels,
      nvidiaModels,
      asicModels,
    } = this.statistics;

    const data = [
      { coins, algos },
      { amdModels, nvidiaModels, asicModels },
      { miners, gpuBrands },
    ];

    const preparedTitles = this.getMessages(TITLES);

    return (
      <div className={s.root}>
        {data.map((row, index) => {
          return (
            <DonutsContainer
              key={index}
              data={row}
              titles={preparedTitles}
            />
          )
        })}
      </div>
    );
  }
}


export default injectIntl(StatisticsContainer);
